<script>
import Layout from "../../layouts/horizontal";
import Swal from "sweetalert2";
import http from '@/http'

export default {
  components: {
    Layout
  },
  data() {
    return {
      timelineData: null,
      loading: false,
      clearing: false,
      errors:[],
      title: this.$t("pages.screeningHistory.title"),
      breadcrumbs: [
        
        {
          text: 'Marketplace',
           to: { name: 'indices' }
        },
        {
          text: this.$t("pages.screeningHistory.title"),
          active: true
        }
      ]
    };
  },
  props: {
  
  },
  created() {
    this.load();
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
    clearHistory() {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
          this.confirmClearHistory();
        }
      });
    },
    async confirmClearHistory() {
      this.clearing = true;
      await http.delete('api/screening');
      this.timelineData = { years: [] };
      this.clearing = false;
    },
    async load() {
      this.loading = true;
      const { data } = await  http.get('api/screening/timeline');
      this.timelineData = data;
      this.loading = false;
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
              {{ title }} <i v-if="loading"  class="fa fa-spin fa-spinner ml-2"></i>
            </span>
          </h4>

          <div class="page-title-right">
            <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button 
          class="btn btn-outline-secondary float-right" 
          @click="clearHistory"
          :disabled="clearing"
        >
          <i class="fa fa-spin fa-spinner mr-2" v-if="clearing"></i>
          <i class="fa fa-times mr-2" v-if="!clearing"></i>
          Clear history
        </button>
      </div>
    </div>
    <div class="alert alert-info mt-2" v-if="timelineData && (!timelineData.years || timelineData.years.length === 0)">
      No records found...
    </div>
    <div class="row justify-content-center  mt-2" v-if="timelineData">
      <div class="col-xl-10">
        <div class="timeline" dir="ltr" v-for="yearData in timelineData.years" :key="yearData.year">
          <div class="timeline-item timeline-left">
            <div class="timeline-block">
              <div class="time-show-btn mt-0">
                <a href="#" class="btn btn-danger btn-rounded w-lg">{{yearData.year}}</a>
              </div>
            </div>
          </div>

        
          <div
            class="timeline-item"
            v-for="(data, index) in yearData.items"
            :key="index"
            :class="{'timeline-left':index % 2 === 0}"
          >
            <div class="timeline-block">
              <div class="timeline-box card cardc8">
                <div class="card-content">
                  <div class="card-body">
                    <span class="timeline-icon"></span>
                    <router-link 
                      :to="{ name: 'screening', params: { id: data.id}}"
                      class="timeline-date">  
                      <i class="mdi mdi-circle-medium circle-dot"></i>
                      {{data.date | moment("ddd, MMM Do")}}
                    </router-link> 
                    <h5 class="mt-3 foont-size-15">
                      <span v-if="data.screeningType === 'Correlations'">{{$t("pages.screeningHistory.screeningType-Correlations")}}</span>
                      <span v-if="data.screeningType === 'RollingCorrelations'">{{$t("pages.screeningHistory.screeningType-RollingCorrelations")}}</span>
                      <span v-if="data.screeningType === 'PerformanceStatistics'">{{$t("pages.screeningHistory.screeningType-PerformanceStatistics")}}</span>
                      <span v-if="data.screeningType === 'PerformanceData'">{{$t("pages.screeningHistory.screeningType-PerformanceData")}}</span>
                      <span class="ml-2" v-if="data.years === 0">({{$t("pages.screeningHistory.allData")}})</span>
                      <span class="ml-2" v-if="data.years === 10">({{$t("pages.screeningHistory.10year")}})</span>
                      <span class="ml-2" v-if="data.years === 1">({{$t("pages.screeningHistory.1year")}})</span>
                      <span class="ml-2" v-if="data.years === 3">({{$t("pages.screeningHistory.3years")}})</span>
                      <span class="ml-2" v-if="data.years === 5">({{$t("pages.screeningHistory.5years")}})</span>
                    </h5>
                    <div class="text-muted">
                      <p class="mb-0 pt-2">
                        <ul style="list-style: none;">
                          <li v-for='i in data.indices' :key="i.indexId">{{i.indexDisplayName}}</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </Layout>
</template>